import { useEffect, useState } from 'react'
import { t } from '../utils'
import { useAuth } from '../utils/auth'
import styles from './_freeTrialBanner.module.scss'
import { useRouter } from 'next/router'

const FreeTrialBanner: React.FC = () => {
  const router = useRouter()
  const { isAuthenticated, isLoading } = useAuth()

  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('_worship_online_used_login='))
        ?.split('=')[1]

      const wasAuthenticated = !!cookieValue

      const isSignupPage = router.asPath.startsWith('/signup')

      setIsShown(!wasAuthenticated && !isSignupPage)
    }
  }, [isAuthenticated, isLoading])

  return (
    <>
      {isShown && (
        <div className={styles.banner}>
          <a href="https://worshiponline.com">{t('Start my free trial')}</a>
        </div>
      )}
    </>
  )
}

export default FreeTrialBanner
